import useFormFieldConfig from "hooks/use-form-field-config";

const useNotificationConfigurationFieldSettings = (orgUnitId, forceLoad) => {
  const { loadingFieldSettings, fieldSettings, loadedFieldSettings } = useFormFieldConfig(orgUnitId, "NotificationConfiguration", forceLoad);

  const fieldLabels = {
    sendToInterestedParties: fieldSettings?.sendToInterestedParties?.label || "Send To Interested Parties",
    sendToApplicant: fieldSettings?.sendToApplicant?.label || "Send To Applicant",
    sendToApprover: fieldSettings?.sendToApprover?.label || "Send To Approver",
    sendToUserId: fieldSettings?.sendToUserId?.label || "Send To User Id",
    sendToAssignedRoleUsers: fieldSettings?.sendToAssignedRoleUsers?.label || "Send To Assigned Role Users",
    sendToPatients: fieldSettings?.sendToPatients?.label || "Send To Patients",
    sendToReferralCreator: fieldSettings?.sendToReferralCreator?.label || "Send To Referral Creator",
    sendToTaskAssigned: fieldSettings?.sendToTaskAssigned?.label || "Send To Task Assigned",
    sendToEndorsementTypes: fieldSettings?.sendToEndorsementTypes?.label || "Send To Endorsement Types",
    sendToSpecialtyRoles: fieldSettings?.sendToSpecialtyRoles?.label || "Send To Specialty Roles",
    notificationDelayMinutes: fieldSettings?.notificationDelayMinutes?.label || "Notification Delay Minutes",
    emailSubject: fieldSettings?.emailSubject?.label || "Email Subject",
    emailBody: fieldSettings?.emailBody?.label || "Email Body",
    mobileBody: fieldSettings?.mobileBody?.label || "Mobile Body",
    sentToDirectEmailAddresses: fieldSettings?.sentToDirectEmailAddresses?.label || "Sent To Direct Email Address (comma separated if multiple)",
  };

  return ({
    loadingFieldSettings,
    fieldSettings,
    fieldLabels,
    loadedFieldSettings,
  });
};

export default useNotificationConfigurationFieldSettings;
