import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import { map, reduce, startCase } from "lodash";

import Form from "components/form";
import { TextInput, CheckboxInput, AutoComplete, TextAreaInput, NumberInput } from "components/inputs";
import { fetchEndorsementTypeConfiguration, saveNotificationConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import LoadingState from "components/items/loading-state";

import { getEndorsementTypeSettings, isCurrentNotificationConfigurationLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import useNotificationConfigurationFieldSettings from "app/main/orgUnits/hooks/useNotificationConfigurationFieldSettings";
import { getAllAssignableRoles, getNotificationContentParams } from "app/auth/store/reducers/system-configuration";
import NotificationConfigurationHelper from "./orgUnit-notification-configuration-helper";

const OrgUnitNotificationConfigurationForm = ({
  disabled,
  current,
  onSucceed,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgUnitId) {
      dispatch(fetchEndorsementTypeConfiguration(orgUnitId));
    }
  }, [orgUnitId, dispatch]);

  const notificationConfigurationLoading = useSelector(state => isCurrentNotificationConfigurationLoading(state, orgUnitId));
  const endorsementTypeSettings = useSelector(state => getEndorsementTypeSettings(state, orgUnitId));
  const allAssignableRoles = useSelector(getAllAssignableRoles);
  const notificationContentParams = useSelector(getNotificationContentParams);
  const allowedParams = map(notificationContentParams, x => x.value);
  const { fieldSettings, fieldLabels } = useNotificationConfigurationFieldSettings(orgUnitId);
  const tooLong = "Too Long!";

  const endorsedByTypesOptions = reduce(endorsementTypeSettings, (results, endorsementTypeSetting) => {
    if (!endorsementTypeSetting.enabled) {
      return results;
    }

    return [...results, { value: endorsementTypeSetting.endorsedByType, label: endorsementTypeSetting.endorsedByTypeLabel }];
  }, []);

  const schema = Yup.object().shape({
    orgUnitNotificationConfiguration: Yup.object().shape({
      sendToInterestedParties: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToInterestedParties, fieldSettings?.sendToInterestedParties)
        .nullable(),
      sendToApplicant: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToApplicant, fieldSettings?.sendToApplicant)
        .nullable(),
      sendToApprover: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToApprover, fieldSettings?.sendToApprover)
        .nullable(),
      sendToEndorsementTypes: Yup.array()
        .fieldSettingsValidation(fieldLabels.sendToEndorsementTypes, fieldSettings?.sendToEndorsementTypes)
        .nullable(),
      sendToUserId: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToUserId, fieldSettings?.sendToUserId)
        .nullable(),
      sendToAssignedRoleUsers: Yup.array()
        .fieldSettingsValidation(fieldLabels.sendToAssignedRoleUsers, fieldSettings?.sendToAssignedRoleUsers)
        .nullable(),
      sendToPatients: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToPatients, fieldSettings?.sendToPatients)
        .nullable(),
      sendToTaskAssigned: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToTaskAssigned, fieldSettings?.sendToTaskAssigned)
        .nullable(),
      sendToReferralCreator: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToReferralCreator, fieldSettings?.sendToReferralCreator)
        .nullable(),
      sendToSpecialtyRoles: Yup.array()
        .fieldSettingsValidation(fieldLabels.sendToSpecialtyRoles, fieldSettings?.sendToSpecialtyRoles)
        .nullable(),
      sendToAllEndorsementTypes: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sendToAllEndorsementTypes, fieldSettings?.sendToAllEndorsementTypes)
        .nullable(),
      emailBody: Yup.string()
        .max(2000, tooLong)
        .fieldSettingsValidation(fieldLabels.emailBody, fieldSettings?.emailBody)
        .validateHandleBarAllowedParams(fieldLabels.emailBody, allowedParams)
        .nullable(),
      emailSubject: Yup.string()
        .fieldSettingsValidation(fieldLabels.emailSubject, fieldSettings?.emailSubject)
        .validateHandleBarAllowedParams(fieldLabels.emailSubject, allowedParams)
        .nullable(),
      mobileBody: Yup.string()
        .max(500, tooLong)
        .fieldSettingsValidation(fieldLabels.mobileBody, fieldSettings?.mobileBody)
        .validateHandleBarAllowedParams(fieldLabels.mobileBody, allowedParams)
        .nullable(),
      notificationDelayMinutes: Yup.string()
        .fieldSettingsValidation(fieldLabels.notificationDelayMinutes, fieldSettings?.notificationDelayMinutes)
        .nullable(),
      sentToDirectEmailAddresses: Yup.string()
        .max(255, tooLong)
        .test("is-valid-emails", "Invalid email address", value => {
          if (!value) return true; // Allow empty values
          const emails = value.split(",").map(email => email.trim());
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emails.every(email => emailRegex.test(email));
        })
        .fieldSettingsValidation(fieldLabels.sentToDirectEmailAddresses, fieldSettings?.sentToDirectEmailAddresses)
        .nullable(),
    }),
  });

  const handleSubmit = ({ orgUnitNotificationConfiguration }, { setSubmitting, setErrors }) => {
    const apiCall = () => dispatch(saveNotificationConfiguration(orgUnitId, orgUnitNotificationConfiguration));

    apiCall().then(res => {
      setSubmitting(false);
      if (res.error !== true) {
        onSucceed();
      } else {
        setErrors(res.payload);
      }
    });
  };

  let { orgUnitNotificationConfiguration } = schema.cast();

  if (current) {
    orgUnitNotificationConfiguration = current;
  }

  if (notificationConfigurationLoading) return (<LoadingState />);

  return (
    <Form
      initialValues={{ orgUnitNotificationConfiguration }}
      onSubmit={handleSubmit}
      disabled={disabled}
      contentProps={other}
      validationSchema={schema}
      isModal={false}
      content={() => (
        <>
          <Field
            name="orgUnitNotificationConfiguration.enabled"
            component={CheckboxInput}
            label="Enabled"
          />
          {fieldSettings?.sendToInterestedParties && (
            <Field
              name="orgUnitNotificationConfiguration.sendToInterestedParties"
              required={fieldSettings.sendToInterestedParties.required}
              component={CheckboxInput}
              label={fieldLabels.sendToInterestedParties}
            />
          )}
          {fieldSettings?.sendToApplicant && (
            <Field
              name="orgUnitNotificationConfiguration.sendToApplicant"
              component={CheckboxInput}
              required={fieldSettings.sendToApplicant.required}
              label={fieldLabels.sendToApplicant}
            />
          )}
          {fieldSettings?.sendToApprover && (
            <Field
              name="orgUnitNotificationConfiguration.sendToApprover"
              component={CheckboxInput}
              required={fieldSettings.sendToApprover.required}
              label={fieldLabels.sendToApprover}
            />
          )}
          {fieldSettings?.sendToUserId && (
            <Field
              name="orgUnitNotificationConfiguration.sendToUserId"
              component={CheckboxInput}
              required={fieldSettings.sendToUserId.required}
              label={fieldLabels.sendToUserId}
            />
          )}
          {fieldSettings?.sendToAssignedRoleUsers && (
            <Field
              name="orgUnitNotificationConfiguration.sendToAssignedRoleUsers"
              component={CheckboxInput}
              required={fieldSettings.sendToAssignedRoleUsers.required}
              label={fieldLabels.sendToAssignedRoleUsers}
            />
          )}
          {fieldSettings?.sendToPatients && (
            <Field
              name="orgUnitNotificationConfiguration.sendToPatients"
              component={CheckboxInput}
              required={fieldSettings.sendToPatients.required}
              label={fieldLabels.sendToPatients}
            />
          )}
          {fieldSettings?.sendToReferralCreator && (
            <Field
              name="orgUnitNotificationConfiguration.sendToReferralCreator"
              component={CheckboxInput}
              required={fieldSettings.sendToReferralCreator.required}
              label={fieldLabels.sendToReferralCreator}
            />
          )}
          {fieldSettings?.sendToTaskAssigned && (
            <Field
              name="orgUnitNotificationConfiguration.sendToTaskAssigned"
              component={CheckboxInput}
              label={fieldLabels.sendToTaskAssigned}
              required={fieldSettings.sendToTaskAssigned.required}
            />
          )}
          {fieldSettings?.sendToEndorsementTypes && (
            <Field
              name="orgUnitNotificationConfiguration.sendToEndorsementTypes"
              label={fieldLabels.sendToEndorsementTypes}
              component={AutoComplete}
              multiple
              required={fieldSettings.sendToEndorsementTypes.required}
              options={endorsedByTypesOptions}
            />
          )}
          {fieldSettings?.sendToSpecialtyRoles && (
            <Field
              name="orgUnitNotificationConfiguration.sendToSpecialtyRoles"
              label={fieldLabels.sendToSpecialtyRoles}
              component={AutoComplete}
              required={fieldSettings.sendToSpecialtyRoles.required}
              multiple
              options={map(allAssignableRoles, x => ({ label: x.roleDescription ?? startCase(x.roleName), value: x.roleName }))}
            />
          )}
          {fieldSettings?.notificationDelayMinutes && (
            <Field
              name="orgUnitNotificationConfiguration.notificationDelayMinutes"
              component={NumberInput}
              negative="true"
              required={fieldSettings.notificationDelayMinutes.required}
              label={fieldLabels.notificationDelayMinutes}
            />
          )}
          {fieldSettings?.emailSubject && (
            <Field
              name="orgUnitNotificationConfiguration.emailSubject"
              component={TextInput}
              required={fieldSettings.emailSubject.required}
              label={fieldLabels.emailSubject}
            />
          )}
          {fieldSettings?.emailBody && (
            <Field
              name="orgUnitNotificationConfiguration.emailBody"
              component={TextAreaInput}
              rows={8}
              label={fieldLabels.emailBody}
              required={fieldSettings.emailBody.required}
            />
          )}
          {fieldSettings?.mobileBody && (
            <Field
              name="orgUnitNotificationConfiguration.mobileBody"
              component={TextAreaInput}
              required={fieldSettings.mobileBody.required}
              label={fieldLabels.mobileBody}
            />
          )}
          {fieldSettings?.sentToDirectEmailAddresses && (
            <Field
              name="orgUnitNotificationConfiguration.sentToDirectEmailAddresses"
              component={TextInput}
              required={fieldSettings.sentToDirectEmailAddresses.required}
              label={fieldLabels.sentToDirectEmailAddresses}
            />
          )}
          <NotificationConfigurationHelper notificationContentParams={notificationContentParams} />
        </>
      )}
    />
  );
};

export default OrgUnitNotificationConfigurationForm;
