/* eslint-disable func-names */
import * as Yup from "yup";
import moment from "moment";
import validator from "au-bn-validator";
import checkIfEmpty from "helpers/check-if-empty";
import { formatUtcDate } from "helpers/format-date-time";
import Handlebars from "handlebars";
import { isBoolean, isEmpty, filter, forEach, find, concat, split } from "lodash";
import { validateIdentifiers } from "./validators";
import { AcceptedDateFormats } from "./date";

/**
 * Keep in mind that ES6 arrow function syntax cannot be used here as it retains the [this] from the enclosing lexical context.
 * You won’t be able to access methods and variables exposed to you by Yup unless you use older, more traditional function() {...} syntax.
 *
 * more info about add method: https://medium.com/@arkadyt/how-does-yup-addmethod-work-creating-custom-validation-functions-with-yup-8fddb71a5470
 */

Yup.addMethod(Yup.string, "validateRequired", function(name) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (checkIfEmpty(value)) {
      return createError({ path, message: `${name} is required` });
    }
    return true;
  });
});

Yup.addMethod(Yup.string, "validateMedicareNumber", function(name) {
  return this.test("validateMedicareNumber", name, function(value) {
    const { path, createError } = this;

    if (value) {
      const isValid = validator.validateMedicareNumber(value);

      if (!isValid) {
        return createError({ path, message: `${name} is invalid` });
      }
    }

    return true;
  });
});

Yup.addMethod(Yup.string, "validateIdentifier", function(name, options) {
  return this.test("validateIdentifier", name, function(value) {
    const { path, createError } = this;
    const validateMessage = validateIdentifiers(name, value, options);

    if (!checkIfEmpty(validateMessage)) {
      return createError({ path, message: `${validateMessage}` });
    }

    return true;
  });
});

Yup.addMethod(Yup.string, "validateMinLength", function(name, minLength) {
  return this.test("validateMinLength", name, function(value) {
    const { path, createError } = this;
    return ((value && value.length >= minLength) || isEmpty(value)
      ? true
      : createError({ path, message: `${name} must be at least ${minLength} characters long.` }));
  });
});

Yup.addMethod(Yup.mixed, "fieldSettingsValidation", function(name, options) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (options?.required && isBoolean(this.options.originalValue) && value === true) {
      return true;
    }

    if (options?.required && checkIfEmpty(value)) {
      return createError({ path, message: `${name} is required` });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.mixed, "validateLink", function(name, isOptional) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (!isOptional && !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?\s*$/.test(value)) {
      return createError({ path, message: "This does not look like a valid URL." });
    }

    if (!isOptional && checkIfEmpty(value)) {
      return createError({ path, message: "Please enter a url" });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.mixed, "fileAttachmentRequired", function(name, isOptional) {
  return this.test("validateRequired", name, function(value) {
    const { path, createError } = this;

    if (!isOptional && checkIfEmpty(value)) {
      return createError({ path, message: "Please choose a file" });
    }
    // add other validations here

    return true;
  });
});

Yup.addMethod(Yup.number, "validateDecimalNumber", function(name, regexPattern) {
  return this.test(
    "validateDecimalNumber",
    `${name} must be less than 1,000,000,000`,
    value => {
      if (!checkIfEmpty(value)) {
        const regex = new RegExp(regexPattern ?? /(^\d{0,9}(?!\.)$)|(^\d{0,9}\.\d{1,2}$)/);
        return regex.test(value);
      }
      return true;
    },
  );
});

Yup.addMethod(Yup.number, "validateDecimalNumberDecimalValues", function(name, regexPattern) {
  return this.test(
    "validateDecimalNumberDecimalValues",
    `${name} can only have up to 2 decimal places`,
    value => {
      if (!checkIfEmpty(value)) {
        const regex = new RegExp(regexPattern ?? /^(\d*(?:,\d{1,2})?(.\d{0,16}e-|.\d{0,16}e\+|.\d{0,16}e|).\d{0,2})$/);
        return regex.test(value);
      }
      return true;
    },
  );
});

Yup.addMethod(Yup.mixed, "validateHandleBarAllowedParams", function(name, allowedParams) {
  return this.test(
    "validateHandleBarAllowedParams",
    name,
    function(value) {
      let errorParams = [];
      let errorMessage = "";
      const { path, createError } = this;
      if (!checkIfEmpty(value)) {
        try {
          const handlebars = Handlebars.parse(value);
          const params = filter(handlebars.body, x => x.type === "MustacheStatement");
          forEach(params, x => {
            if (!find(allowedParams, y => y === x.path.original)) {
              errorParams = concat(errorParams, x.path.original);
              errorMessage = `The ${errorParams.length > 1 ? "parameters" : "parameter"} ${errorParams.join(", ")} ${errorParams.length > 1 ? "are" : "is"} not valid.`;
            }
          });
        } catch (e) {
          errorMessage = `Template Syntax Issue: "${split(e, "----")[0]}"`;
        }
      }

      return isEmpty(errorMessage) ? true : createError({ path, message: errorMessage });
    },
  );
});

Yup.addMethod(Yup.mixed, "validateAllowedFutureDateMinutes", function(name, allowedFutureDateMinutes) {
  return this.test("validateAllowedFutureDateMinutes", name, function(value) {
    const { path, createError } = this;
    if (!value || allowedFutureDateMinutes === null) {
      return true;
    }

    const momentVal = moment(value, AcceptedDateFormats);
    const currentDatePlusMinutes = moment().clone().add(allowedFutureDateMinutes, "minutes");
    const isValid = moment(momentVal).isBefore(currentDatePlusMinutes);

    return (isValid ? true : createError({ path, message: `${name} must be before ${formatUtcDate(currentDatePlusMinutes, "DD-MMM-YY HH:mm")}` }));
  });
});
