import React from "react";
import { Typography } from "@material-ui/core";
import HelpRhombusOutlineIcon from "mdi-react/HelpRhombusOutlineIcon";
import DefaultPanelLayout from "components/items/default-panel-layout";
import DefaultItem from "components/items/default-item";

const Item = ({ label, ...props }) => <DefaultItem labelClassName="w-256" label={<Typography className="font-bold" variant="subtitle2">{"{{"}{label}{"}}"}</Typography>} {...props} />;

const NotificationConfigurationHelper = ({ notificationContentParams }) => (
  notificationContentParams && (
    <DefaultPanelLayout
      title="Email and SMS Template Parameters"
      icon={<HelpRhombusOutlineIcon />}
    >
      <div className="items-container">
        {notificationContentParams.map((item, index) => <Item key={index.toString()} label={item.value} content={item.label} />)}
      </div>
    </DefaultPanelLayout>
  )
);

export default NotificationConfigurationHelper;
